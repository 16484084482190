$(function () {
  if ($('body').hasClass('neos-backend')) return;

  $('.obis-site-gallery').each(function (index, elem) {
    $(elem).find('.obis-site-gallery-item').zoombox({
      centerImages: true,
      closeOnBlurClick: true,
      containerId: ('zoombox-overlay-' + index),
      disableTouchMove: false,
      enableAutoplay: false,
      fadeDuration: 500,
      ignoreInputOnMove: true,
      listenKeys: true,
      showAsGallery: true,
      sliderDraggable: true,
      sliderLooped: false,
      slideTransition: 'ease',
      slideTransitionSpeed: 500,
    }).addClass('zoombox-overlay');
  });
});